.log_sign {
  font-size: 1.6rem;
  color: var(--color_text);
}
.link_style {
  text-decoration: none;
  color: var(--blue);
  font-weight: bold;
  font-size: 1.6rem;
}
.link_style:hover {
  opacity: 0.8;
}
