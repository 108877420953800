.ManageForms__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 10px;
}
.ManageForms__btns {
  display: flex;
  gap: 10px;
  flex-wrap: wrap-reverse;
  justify-content: center;
}
.FormSteps {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 10px;
}
.FormSteps__card {
  background-color: var(--white);
  width: 350px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid var(--white);
  cursor: pointer;
}
.FormSteps__card:hover {
  border: 1px solid var(--blue);
}
.FieldCard {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
.FieldCard__main {
  width: 100%;
  background-color: #f4f5fc;
  padding: 10px;
  padding-top: 5px;
  border-radius: 5px;
}
.FieldCard__option {
  display: flex;
  gap: 5px;
}
.FieldCard__option i {
  border-radius: 5px;
  background: #f4f5fc;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: var(--blue);
  cursor: pointer;
  border: 1px solid var(--white);
}
.FieldCard__option i:hover {
  border: 1px solid var(--blue);
  transition-duration: 0.6s;
}
.FieldCard__option i.iconRed {
  color: var(--red);
  background: #c93f3f1a;
}
.FieldCard__option i.iconRed:hover {
  border: 1px solid var(--red);
}
