.Template {
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
}
.Template__main {
  padding: 10px 0 100px;
  display: flex;
  gap: 10px;
}
.Template__container {
  /* height: calc(100vh - 130px); */
  width: 100%;
  overflow-y: auto;
  padding-right: 5px;
}
*::-webkit-scrollbar {
  width: 1rem;
  height: 1.2rem;
}
*::-webkit-scrollbar-track {
  background: transparent;
  margin: 10px 0;
}
*::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #05269183;
  border-radius: 25px;
}
.Header {
  height: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background: white;
}
.Header__navItem img {
  height: 50px;
}
.Navbar {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* .Account {
  width: 30px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
  background-image: url('https://res.cloudinary.com/dzxmzxvbr/image/upload/v1614259401/user_h6o64n.jpg');
  background-size: contain;
} */
.Header__navItem i {
  font-size: 2.2rem;
  cursor: pointer;
}
.Header__icon i {
  color: var(--gray);
}
.Header__icon i:hover {
  color: var(--blue);
  opacity: 0.8;
}
.Navbar__link {
  color: var(--gray);
  height: 60px;
  line-height: 65px;
  text-align: center;
  padding: 0 5px;
}
.Navbar__link:hover {
  color: var(--blue);
  opacity: 0.8;
}
.Navbar__linkAc {
  opacity: 0.8;
  color: var(--blue);
  border-bottom: 2px solid var(--blue);
}

.GiveFeedback .RadioButtons__itemContainer {
  justify-content: space-between;
}
.GiveFeedback .RadioButtons__item {
  margin: 10px 0;
}
.GiveFeedback .RadioButtons__item label {
  font-size: 3rem;
  padding: 5px 5px 10px;
  filter: grayscale(100%);
  cursor: pointer;
}
.GiveFeedback .RadioButtons__item input[type='radio']:checked + label:after {
  display: none;
}
.GiveFeedback .RadioButtons__item label::before {
  display: none;
}
.GiveFeedback .RadioButtons__item input[type='radio']:checked + label {
  background: rgb(228, 228, 228);
  border-radius: 50%;
  filter: grayscale(0%);
  box-shadow: 0 0 10px rgb(228, 228, 228);
}
