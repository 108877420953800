.Auth {
  position: absolute;
  top: 0;
  left: 0;
}
.login {
  background-color: #f4f5fc;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.login_view {
  box-shadow: 0 0 10px #d9ddfc;
  padding: 10px;
  height: 70%;
  width: 60%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.login_view_img {
  width: 50%;
  height: 80%;
  padding: 0 10px;
  border-right: #c0c2c9 2px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login_view_img .login_imgLogo {
  width: 100%;
  transform: translateY(-50px);
}
.login_view_img .login_imgCertificate {
  width: 15%;
  align-self: flex-end;
}
.login_view_form {
  width: 60%;
  min-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .login_view {
    width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .login_view_img {
    display: none;
  }
  .login_view_form {
    width: 90%;
    margin-right: 20px;
  }
}
