.RecoverSteps__processStep {
  display: flex;
  width: 350px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 5px auto;
  padding: 5px;
  border-radius: 5px;
}
