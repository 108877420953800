.searchContainer {
  background: var(--white);
  border-radius: 10px;
  height: 35px;
  padding: 0 5px 0 10px;
  display: flex;
  align-items: center;
  border: 1px solid #e2e4f37c;
  font-size: 1.6rem;
  gap: 10px;
}
.searchContainer i {
  color: var(--blue);
  filter: opacity(0.5);
}
.searchContainer #times {
  background-color: #e2e4f3;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 5px;
  filter: opacity(0.8);
  border: 1px solid #e2e4f3;
  cursor: pointer;
}
.searchContainer #times:hover {
  opacity: 0.8;
  box-shadow: 0px 1px 20px -2px #a1a9e7;
  border: 1px solid var(--blue);
}
.searchContainer a {
  text-decoration: none;
}
.searchContainer__input {
  border: none;
  background: none;
  outline: none;
  color: var(--color2);
  font-size: 1.6rem;
  font-weight: normal;
  min-width: 100px;
  width: calc(100% - 200px);
  /* width: calc(100% - 200px); */
}
.searchContainer__input::placeholder {
  color: var(--color6);
  opacity: 0.7;
}
.Search__select {
  border: none;
  background-color: #e2e4f3;
  padding: 5px 8px;
  border-radius: 5px;
  outline: none;
  font-size: 1.4rem;
}
