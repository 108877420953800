.GraphicsFilters {
  background: var(--white);
  margin: 10px 0;
  border-radius: 10px;
  max-width: 100vw;
}
.GraphicsFilters__header {
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  gap: 10px;
  align-items: center;
}
.GraphicsFilters__main {
  overflow: auto;
}
