.UserMenu__contain {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.UserMenu {
  position: relative;
}
.UserMenu__card {
  position: absolute;
  right: 0;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px 8px;
  margin-top: 10px;
  z-index: 1;
}
.UserMenu__card .TextH3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.UserMenu__card .TextH3:hover {
  color: var(--blue) !important;
}
.HeaderNotify {
  position: relative;
}
.HeaderNotify__icon {
  position: relative;
  cursor: pointer;
}
.HeaderNotify__icon > div {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background: #f02929;
  position: absolute;
  left: 10px;
  top: 0;
  border: 2px solid var(--white);
}
.HeaderNotify__icon .TextH3 {
  border-radius: 20px;
  padding: 3px 6px;
  background: #f02929;
  position: absolute;
  left: 10px;
  top: -5px;
  border: 2px solid var(--white);
}
.HeaderNotify__main {
  position: absolute;
  min-height: 10px;
  min-width: 250px;
  right: 0;
  background: var(--white);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
  z-index: 1;
}
.HeaderNotify__list {
  max-height: 250px;
  overflow-y: auto;
}
.Header__link {
  background: rgb(255, 238, 227);
}
