.Account__avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid var(--gray);
  background-size: contain;
}
.Account__preference {
  width: 95%;
  margin: 0 auto;
}
.Account__preference .form-control {
  width: 98.5%;
  justify-content: space-between;
  border: 1px solid #5f596162;
  padding: 8px 5px;
  border-radius: 8px;
}
.Account__preference .form-control label {
  color: #252e48;
}
