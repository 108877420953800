.form-control.DatePicker .react-datepicker {
  font-size: 1rem;
  /* transform: scale(1.3) translate(20px, 0); */
  transform: scale(1.3) translate(20px, 18px);
  font-family: 'Spartan', sans-serif;
}
.form-control.DatePicker {
  margin: 0;
  width: auto;
  border-radius: 10px;
}
.form-control.DatePicker .react-datepicker__triangle {
  display: none;
}
.form-control .react-datepicker {
  border-radius: 10px;
  color: var(--gray);
}
.form-control .react-datepicker__header {
  border-radius: 10px 10px 0 0;
  padding-bottom: 15px;
  padding: 12px 0 10px;
  color: var(--gray);
  font-size: 1.1rem;
}
.form-control.DatePicker {
  display: flex;
  flex-direction: column;
}
