.LineCircle__process {
  border: 1px solid white;
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.LineCircle__linearBar {
  top: 0;
  height: 5px;
  border-radius: 20px;
  margin-top: 12px;
}
.LineCircle__circle {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.LineCircle__circle > div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
