.FilterBoxes label {
  font-size: 1.6rem;
  color: rgb(77, 78, 99);
}
.Filter__input input {
  font-size: 1.6rem;
  padding: 4px 5px;
  border-radius: 5px;
  border: 1px solid var(--gray);
  outline: none;
}
.Filter__input input:focus {
  border: 1px solid var(--blue);
}
