.Management__filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.react-datepicker-popper {
  z-index: 2;
}
.Management__filters .Management__year input {
  width: 50px;
  border-radius: 20px;
  font-weight: 600;
  border: 1px solid var(--gray);
  color: var(--gray);
}
.Management__year .react-datepicker {
  border-radius: 10px;
  color: var(--gray);
}
.Management__year .react-datepicker__header {
  border-radius: 10px 10px 0 0;
  padding-bottom: 15px;
  padding: 12px 0 10px;
  color: var(--gray);
  font-size: 1.1rem;
}
.Management__filters .Management__year input:focus {
  border: 1px solid var(--blue);
  color: var(--blue);
}
.Management__year .react-datepicker__year-wrapper > div {
  padding: 5px 0;
}
.Management__filters .Management__select {
  width: fit-content;
  padding: 0;
}
.Management__filters .Management__select select {
  padding: 7px 10px 7px 10px;
  height: fit-content;
  border-radius: 20px;
  border: 1px solid var(--gray);
  color: var(--gray);
  font-weight: 600;
}
.Management__filters .Management__select select:focus {
  border: 1px solid var(--blue);
  background: #e2e4f3;
  color: var(--blue);
}
.Management__date {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Management__filters .Management__select {
  transform: translateY(2px);
}
.ButtonRangeDate {
  position: relative;
}
.ButtonRangeDate__btn {
  width: fit-content;
  padding: 6px 10px 6px 10px;
  border: 1px solid var(--gray);
  border-radius: 20px;
  border: 1px solid var(--gray);
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--gray);
  background: var(--white);
}
.ButtonRangeDate__btn.activate {
  border: 1px solid var(--blue);
  background: #e2e4f3;
  color: var(--blue);
}
.ButtonRangeDate__modal {
  max-width: 350px;
  min-width: 250px;
  padding: 10px 15px;
  background: var(--white);
  position: absolute;
  z-index: 1;
  border-radius: 15px;
  right: 0;
}
.ButtonRangeDate__modal .react-datepicker {
  font-size: 1rem;
  transform: scale(1.3) translate(0, 20px);
  font-family: 'Spartan', sans-serif;
}
.ButtonRangeDate__modalClose {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: #05269107;
  z-index: 0;
}
