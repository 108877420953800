.BarColumn {
  display: grid;
  gap: 5px 10px;
  margin-right: 20px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .Bar .BoxFlex .Bar__label {
    display: none;
  }
  .BarColumn {
    display: grid;
    gap: 5px 10px;
    margin-right: 20px;
    grid-template-columns: auto;
    text-align: center;
  }
}
