.form-control.RangeDate label {
  color: var(--black);
}
.form-control.RangeDate {
  display: flex;
  align-items: center;
  gap: 5px;
}
.form-control.RangeDate .react-datepicker {
  font-size: 1rem;
  transform: scale(1.3) translate(20px, 18px);
}
.form-control.RangeDate {
  margin: 0;
  width: auto;
  border-radius: 10px;
}
.form-control.RangeDate .react-datepicker__input-container input {
  padding: 0 10px;
  border: none;
  font-size: 1.4rem;
  background: #e2e4f3;
  /* '#a6aef0' : '#e2e4f3' */
}
.form-control.RangeDate .react-datepicker__input-container button {
  right: -10px;
}
