.CardBorder {
  gap: 10px 0;
  display: flex;
  border-radius: 30px;
  background: var(--white);
}
.CardBorder__label {
  background-color: #7889fd;
  border-radius: 30px 0 30px 30px;
  display: flex;
  align-items: center;
}
.CardBorder__main {
  display: flex;
  padding: 10px 15px 5px;
  flex-direction: column;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .CardBorder {
    flex-direction: column;
  }
  .CardBorder__label {
    background-color: #7889fd;
    border-radius: 30px 30px 0 30px;
  }
  .CardBorder__label > div {
    writing-mode: horizontal-tb !important;
    transform: rotate(0) !important;
  }
}
