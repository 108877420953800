.form-control {
  text-align: start;
  margin: 10px 15px 10px 0;
  width: 100%;
}
.form-control label {
  font-size: 1.5rem;
  color: gray;
}
.form-control input {
  border: 1px solid #b1b1b1;
  outline: none;
  background: none;
  width: 95%;
  font-size: 1.6rem;
  height: 25px;
  border-radius: 5px;
  background: var(--white);
  padding: 5px 8px;
}
.form-control input:focus {
  border: 1px solid var(--blue);
}
.form-control input:disabled {
  background-color: rgb(219, 219, 219);
}
.error {
  margin: 5px 0;
  color: #d57272;
  font-size: 1.4rem;
}
.form-control select {
  border: 1px solid #b1b1b1;
  height: 45px;
  outline: none;
  width: 98.5%;
  font-size: 1.6rem;
  border-radius: 5px;
  background: var(--white);
  padding: 5px 8px;
}
.form-control select:focus {
  border: 1px solid var(--blue);
}
.form-control select:disabled {
  background-color: rgb(219, 219, 219);
  color: var(--black);
}
.Input__check {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.Input__check input {
  margin: 0;
  width: auto;
}
.formInput__grid {
  display: grid;
  grid-template: auto / 1fr 1fr;
  row-gap: 5px;
}
.Textarea .Textarea__label {
  font-size: 1.5rem;
  color: gray;
}
.Textarea__input {
  resize: vertical;
  border: 1px solid #b1b1b1;
  outline: none;
  background: none;
  width: 95%;
  font-size: 16px;
  height: 75px;
  min-height: 75px;
  max-height: 200px;
  font-family: Arial;
  border-radius: 5px;
  background: var(--white);
  padding: 5px 8px;
}

.RadioButtons .RadioButtons__label {
  font-size: 1.5rem;
  color: gray;
}
.RadioButtons .RadioButtons__input {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 10px;
  flex-direction: row;
}
.RadioButtons .RadioButtons__itemContainer {
  display: flex;
}

.RadioButtons__item {
  position: relative;
  padding: 0 6px;
  font-size: 14px;
  margin: 10px;
}

.RadioButtons__item input[type="radio"] {
  display: none;
}

.RadioButtons__item label {
  color: #666;
  font-weight: normal;
}

.RadioButtons__item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 3.5px;
  margin: 0 5px 0 0;
  width: 15px;
  height: 15px;
  border-radius: 11px;
  border: 2px solid var(--blue);
  background-color: transparent;
}

.RadioButtons__item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 8px;
  left: 10px;
  content: " ";
  display: block;
  background: var(--blue);
}

/* .FormControl__select .css-1nmdiq5-menu {
  height: 100px;
} */
