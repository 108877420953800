.Sidebar {
  width: 200px;
  padding: 5px 5px;
  border-radius: 5px;
  background: var(--white);
  height: calc(100vh - 80px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: none;
  overflow: auto;
}
.Sidebar::-webkit-scrollbar {
  width: 1rem;
}
.Sidebar::-webkit-scrollbar-track {
  margin: 10px 0;
}
.Sidebar::-webkit-scrollbar-thumb {
  background: #05269152;
  border-radius: 25px;
}
.Sidebar__link {
  width: 100%;
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  font-size: 1.4rem;
  color: var(--blue);
  align-items: center;
  border-radius: 0 5px 5px 0;
  justify-content: space-between;
  text-decoration: none;
}
.Sidebar__link:hover {
  background: #0526911a;
}
.Sidebar__link i {
  margin-right: 10px;
}
.Sidebar__linkAc {
  background: #0526911a;
}
.Sidebar__subMenu {
  display: flex;
  flex-direction: column;
}
.Sidebar__subItem {
  text-decoration: none;
  cursor: pointer;
  padding: 10px 0;
  margin-left: 10px;
  border-left: 2px solid var(--gray);
  color: var(--gray);
}
.Sidebar__subItem .ContentPending {
  color: red;
  opacity: 0.7;
  font-weight: 900;
  padding: 0 8px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid red;
}
.Sidebar__subItem:hover {
  border-left: 3px solid var(--blue);
  color: var(--blue);
  background: #80808015;
}
.Sidebar__subLinkAc {
  border-left: 3px solid var(--blue);
  color: var(--blue);
  background: #80808015;
}
.CustomerFlow__header {
  text-align: center;
}
@media only screen and (max-width: 900px) {
  .Sidebar {
    position: absolute;
    z-index: 2;
  }
  .Sidebar__back {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: #0526911f;
    position: absolute;
    z-index: 1;
  }
}
