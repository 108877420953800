.TableDefault {
  background: var(--white);
  border-radius: 10px;
}
.TableDefault__header {
  display: grid;
  border-bottom: 1px solid var(--blue);
  padding: 10px 10px 5px;
  gap: 10px;
}
.TableDefault__cell {
  display: grid;
  color: #5f5f5f;
  align-items: center;
}
.TableDefault__column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  gap: 8px;
}
.TableDefault__cell .TextH3 {
  overflow-wrap: anywhere;
}
.TableDefault__cell:hover {
  background: #0526911c;
  cursor: pointer;
}
.TableDefault__cell-activate {
  background: #0526911c;
  color: var(--blue);
}
