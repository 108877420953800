.ClientResume__img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--gray);
  background-size: contain;
  background-position: center;
}
.ClientResume__imgUsers {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--gray);
}
